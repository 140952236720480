<template>


	<transition name='fade'>
		<router-view></router-view>
	</transition>

</template>
script
<style>
	.black{
		color: #000 !important;
	}
	.bold{
		font-weight: bold;
	}
	.lf_group_tit_left {
	
		margin-left: 0.2rem;
	}
	.hand{
		 cursor: pointer;
	}
	/* 公用CSS */
	.lf_match_info_box{

		font-size: 0.24rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		overflow: hidden;
		margin: 0 0.2rem;
		
	}

	.d_title {
		margin-left: 0.2rem;
		color: rgb(192, 21, 62);
		font-size: 0.28rem;
		padding-bottom: 0.04rem;
		/* font-weight: bold; */
		font-family: '微软雅黑';
		border-bottom: 0.02rem solid rgb(192, 21, 62);
		display: inline-block;
		padding-right: 0.05rem;
		padding-left: 0.05rem;
	}

	.d_title span {
		font-size: 0.24rem
	}

	.d_img {
		width: 0.3rem;
		height: 0.3rem;
		float: right;
		margin-top: 0.06rem;
		
		transform: rotate(90deg);
	}

	.head {
		width: 18.5rem;
		margin: 0 auto;
		margin-top: 0.68rem;
		height: auto;
	}

	.head_top {
		width: 100%;
	
		display: flex;
		position: relative;
	
	}

	.searchimg {
	
		width: 0.3rem;
		height: 0.3rem;
		
		margin-top: 0.1rem;
	}

	.searchbox {
		width: 5.5rem;
		height: 0.5rem;
		border-radius: 0.1rem;
		background-color: #fafafa;
	
		margin-left: 0.2rem;
	}

	.searchbox input {
		width: 3.6rem;
		height: 0.5rem;
		float: left;
		border-radius: 0.1rem;
		font-size: 0.26rem;
		color: #000;
		margin-left: 0.2rem;
		background-color: #fafafa;
	}


	.searchbutton {
		width: 1.5rem;
		height: 0.5rem;
		margin-left: 0.2rem;
		background-color: #C0153E;
		border-radius: 0 0.1rem 0.1rem 0;
		line-height: 0.5rem;
		font-size: 0.26rem;
		color: #fff;
		float: left;
		text-align: center;
		cursor: pointer;
	}

	.searchimg img {
		width: 0.3rem;
		height: 0.3rem;
	}

	.searchword {
		margin-left: 0.7rem;
		font-size: 0.18rem;
		color: #999999;
		overflow: hidden;
		clear: both;
		margin-top: 0.1rem;
	}

	.headwordbox {
		width: 18.5rem;
		height: 0.4rem;
		line-height: 0.4rem;
		color: #C0153E;
		text-align: left;
		text-indent: 0.4rem;
		font-size: 0.3rem;
		background-color: #FAFAFA;
		margin-top: 0.3rem;
		margin-left: 0.3rem;
	}

	.search {
		position: absolute;
		right: 0.1rem;
		font-size: 0.28rem;
		height: 0.4rem;
		line-height: 0.4rem;
		color: #C0153E;
		margin-top: 0.1rem;
		
		border-bottom:  #C0153E 0.01rem solid;
		
		
	}
	.kindbox{
		min-width: 1.3rem;
		display: block;
		text-align: center;
	}
	.close_search{
		float: right;
		width: 0.3rem;
		height: 0.2rem;
		line-height: 0.2rem;
		text-align: center;
		color: #fff;
		font-size: 0.24rem;
		margin-top: 0.1rem;
		border-radius: 0.04rem;
		margin-right: 0.2rem;
		background-color: #C0153E;
	}
	.search_pos{

		position: relative;
		height: 0.4rem;
	}
	.search span {
		float: left;
		font-size: 0.24rem;
		color: #C0153E;
		margin-right: 0.1rem;
	}
	.search img {
		width: 0.3rem;
		height: 0.3rem;
		transform: rotate(90deg);
		float: left;
		margin-top:0.05rem

	}
	.select-left{
		
		color: #333333;
		font-size: 0.26rem;
		left: 0;
		top: 0.6rem;
		width: 2.6rem;
		max-height:2.6rem;
		position: absolute;
		z-index: 10;
		background-color: rgba(255, 255, 255, 0.7);
		 display: none;
		overflow: hidden;
		border-radius: 0.1rem;
		border:#C0153E 0.01rem solid;
	}
	.select-right{
		
		color: #333333;
		font-size: 0.26rem;
		right: 0;
		top: 0.5rem;
		width: 2.6rem;
		  max-height:2.6rem;
		position: absolute;
		
		background-color: rgba(255, 255, 255, 0.7);

		 display: none;
		overflow: hidden;
		z-index: 10;
		border-radius: 0.1rem;
		border:#C0153E 0.01rem solid;
	
	}
	.select_title{
		display: none;
	}

	.select_socl{
		width: 2.6rem;
		max-height: 2.4rem;
		
		margin-top: 0.1rem;
		
		overflow: scroll;
	}

	.select_item{
		width: 2.6rem;
		height: 0.4rem;
		padding-left:0.17rem;
		margin-bottom: 0.05rem;
		line-height: 0.4rem;

		font-size: 0.24rem;
		overflow: hidden;

	}
	.select_item:hover{
		background-color: rgba(255, 241, 242, 1);;
		color: #C0153E;
	}
	.select_item_hover{
		background-color: rgba(255, 241, 242, 1);;
		color: #C0153E;
	}
	.into{
		animation-name: into;
		 animation-duration: 0.5s;
		 display: block;
	}
	.move{
		animation-name: move;
		 animation-duration: 1s;
		 display: none;
	}
	@keyframes into {
	    0% {
	        max-height:0;
	    }
	    100% {
	        max-height:2.6rem;
	    }
	}
	@keyframes move {
	    0% {
	        max-height:2.6rem;
	    }
	    100% {
	        max-height:0;
			
	    }
	}
	.changebox {
		width: 100%;

		height: 0.7rem;


	}

	.changekind {
		float: left;
		position: relative;

	}



	.timeword {
		float: left;
		margin-right: 0.1rem;
		
	}

	.changetime {
		/* width: 2.2rem; */
		text-align: center;
		border-radius: 0.1rem;
		height: 0.5rem;
		line-height: 0.5rem;

		font-size: 0.24rem;
		color: #C0153E;
		float: right;
		position: relative;
		

	}

	.changetime img {
		width: 0.3rem;
		height: 0.3rem;
		float: right;
		margin-top: 0.1rem;

	}




	.all_body {
		width: 18.5rem;
		margin: 0.3rem auto;
		overflow: hidden;
	}

	.lf_tab_tit_box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-bottom: 0.2rem;
	}

	.lf_tab_tit_box .el-select {
		position: absolute;
		left: 0.1rem;
	}

	.lf_tab_box {
		width: 7.99rem;
		height: 0.5rem;
		display: flex;
		align-items: center;
		margin-top: 0.4rem;
		border-right: unset;
		border-radius: 0.08rem;
		position: relative;
		border-right: none;
	}
	
	.lf_tab_box div {
		width: 1.6rem;
		height: 0.5rem;
		font-size: 0.26rem;
		color: #666666;
		border: 1px solid rgba(153, 153, 153, 1);
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		cursor: pointer;
		position: relative;
		flex-shrink: 0;
	}

	.lf_tab_box div:nth-of-type(1) {
		border-radius: 0.08rem;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		position: relative;
	}
	.lf_tab_box div:nth-last-of-type(1){
		border-radius: 0.08rem;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-right: 1px solid rgba(153, 153, 153, 1);
	}

	.lf_tab_box div:nth-of-type(2) {
		border-radius: 0;
	}
	.lf_tab_box div:nth-last-of-type(1).lf_tab_box_select{
		border-right: 1px solid #C0153E;
	}
	.lf_tab_box div:hover{
		border: #C0153E 1px solid!important;
		color: #C0153E!important;
		position: relative;
		z-index: 99;
		font-weight: bold;
	}
	.lf_tab_box_new div:hover{
		border: #C0153E 1px solid!important;
		color: #C0153E!important;
		position: relative;
		z-index: 99;
		font-weight: bold;
	}
	.lf_tab_box .lf_tab_box_select {
		border: #C0153E 1px solid;
		color: #C0153E;
		position: relative;
		z-index: 99;
		font-weight: bold;
	}
	
	
	.lf_tab_box_newSwiper{
		width: 7.99rem;
		height: 0.5rem;
		display: flex;
		align-items: center;
		margin-top: 0.4rem;
		border-right: unset;
		border-radius: 0.08rem;
		position: relative;
		border-right: none;
	}
	.lf_tab_box_newSwiper div{
		width: 1.6rem;
		height: 0.5rem;
		font-size: 0.26rem;
		color: #666666;
		border:1px solid rgba(153, 153, 153, 1);
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		cursor: pointer;
		position: relative;
		flex-shrink: 0;
	}
	
	
	
	.lf_tab_box_newSwiper div:nth-of-type(1) {
		border-radius: 0.08rem;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		position: relative;
	}
	.lf_tab_box_newSwiper div:nth-last-of-type(1){
		border-radius: 0.08rem;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-right: 1px solid rgba(153, 153, 153, 1);
	}
	
	.lf_tab_box_newSwiper div:nth-of-type(2) {
		border-radius: 0;
	}
	.lf_tab_box_newSwiper div:nth-last-of-type(1).lf_tab_box_select{
		border-right: 1px solid #C0153E;
		width: 1.6rem;
	}
	
	.lf_tab_box_newSwiper .lf_tab_box_select {
		border: #C0153E 1px solid;
		color: #C0153E;
		position: relative;
		z-index: 99;
	}
	
	

	.lf_footer1 {
		width: 100%;
		margin: 0 auto;
		margin-top: 0.7rem;
		
	}

	.lf_footer1_tit_box {
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.lf_footer1_tit_box .lf_foot_tit_text1 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.3rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_text2 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.26rem;
		color: #C0153E;

	}

	.lf_footer1_tit_box .lf_foot_tit_line {
		width: 2.1rem;
		height: 0.03rem;
		background-color: #C0153E;
		margin:  0.05rem 0;
	}
	.headlogo{
		margin-left: 0.1rem;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #333;
	}

	#nav {
		padding: 0.03rem;
	}

	#nav a {
		font-weight: bold;
		color: #333;
	}

	#nav a.router-link-exact-active {
		color: #42b983;
	}

	html {
		/*谷歌浏览器字体最小字体12px*/
		-webkit-text-size-adjust: 100%;
		/*100%/none 关闭字体大小自动调整功能*/
		/*a标签及拥有 :active伪类的元素有默认的高亮框*/
		-webkit-tap-hightlight-color: transparent;
	}

	body {
		overflow-x: hidden;
		/*开启moblie网页快速滚动和回弹的效果*/
		-webkit-overflow-scrolling: touch;
		font-size: 0.24rem;
	}
	body { /* IOS禁止微信调整字体大小 */
		 -webkit-text-size-adjust: 100% !important;
		 text-size-adjust: 100% !important;
		 -moz-text-size-adjust: 100% !important;
		}

	* {
		margin: 0;
		padding: 0;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	a {
		display: block;
		width: 100%;
		color: #333;
		text-decoration: none;
	}

	img {
		border: 0;
		display: unset;
		vertical-align: middle;
	}

	a,
	img {
		/*阻止长按页面，弹出菜单的方法。*/
		-webkit-touch-callout: none;
	}

	input,
	textarea,
	select {
		-webkit-appearance: none;
		outline: none;
		border: none;
	}

	.fl {
		float: left;
	}

	.fr {
		float: right;
	}

	.clearfix:after {
		content: "";
		display: block;
		width: 100%;
		height: 0;
		clear: both;
	}

	li,
	ol {
		list-style: none;
	}

	/*修改placeholder的字体颜色*/
	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #999;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #999;
	}

	input:-ms-input-placeholder {
		color: #999;
	}

	input::-webkit-input-placeholder {
		color: #999;
	}
	.banner_top{
		width: 100%;
		height: 2.25rem;
	}
	.lf_close_img{
		cursor: pointer;
	}
	.lf_entry_info_box_left_con_list_right .el-date-editor{
		width: 7.8rem!important;
		height: 0.6rem!important;
	}
	.lf_entry_info_box_left_con_list_right .el-select{
		width: 7.8rem!important;
		height: 0.6rem!important;
	}
	.lf_entry_info_box_left_con_list_right .select-trigger{
		width: 7.8rem!important;
		height: 0.6rem!important;
	}
	.lf_entry_info_box_left_con_list_right .el-input{
		width: 7.8rem!important;
		height: 0.6rem!important;
	}
	.lf_entry_info_box_left_con_list_right .lf_sssb{
		width: 2rem!important;
	}
	.lf_entry_info_box_left_con_list_right .lf_sssb1{
		width: 5.6rem!important;
	}
	.lf_que_span{
		width: 0.26rem;
		height: 0.26rem;
		border-radius: 50%;
		background-color: #C0153E;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 0.24rem;
		margin-left: 0.1rem;
		margin-top: 2px;
		z-index: 1;
		margin-right: 0.2rem;
		cursor: pointer;
	}
	.lf_que{
		position: relative;
		z-index: 999;
	}
	.lf_entry_info_box_left_con_list .el-button{
		width: 2.6rem;
		height: .6rem;
		background-color: #C0153E;
		margin: 0.3rem 0;
		font-size: 0.26rem;
	}
	.lf_entry_info_box_left_con_list .el-checkbox__label{
		color:#666;
		font-size: 0.24rem;
	}
	.hoverUnderLine:hover{
		text-decoration: underline!important;
	}
	/* @media screen and (min-width: 768px) and (max-width:1199px) {
		    
			.all_body{
				width: 100%;
			}
			.lf_footer1{
				width: 100%!important;
			}
			.lf_course_name_data_box{
				width: 100%!important;
			}
		} */
	@media screen and (max-width: 768px) {
		.lf_entry_info_box_left_con_list .el-checkbox__label{
			width: 6.5rem;
			white-space:normal;
		}
		.head {
			width: 7.02rem;
			
			margin: 0 auto;
			margin-top: 0.5rem;
		}
		.headwordbox{
			width: 7.02rem;
		}
		.all_body {
			width: 7.5rem;
			margin-bottom: 0.5rem;
		}
		
		.changebox {
			width: 7.02rem;
			
			margin: 0 auto;
			margin-top: 0.4rem;
		}
		.search{
			border:none;
			margin-right: 0.2rem;
		}
		.kindbox{
			min-width: 1.1rem;
			display: block;
			text-align: center;
		}
		.search span {
			
			font-size: 0.24rem;
		
		}
		.searchbox {
			width: 4.6rem;
			margin-left: 0.1rem;


		}

		.searchbox input {
			width: 2.87rem;

		}

		.searchbutton {
			width: 1.4rem;
			margin-left: 0.1rem;
			
		}

		

		.lf_footer1_tit_box {
			width: 100%;
			height: 0.9rem;
		}


		.lf_footer1_tit_box .lf_foot_tit_text2 {
			font-size: 0.26rem;
		}


		.lf_footer1_tit_box .lf_foot_tit_text1 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.28rem;
			color: #C0153E;
		}






		.lf_tab_tit_box {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			margin-bottom: 0.2rem;
		}

		.lf_tab_tit_box .el-select {
			position: absolute;
			left: 0.1rem;
		}

		.lf_tab_box {
			width: 6.99rem;
			height: 0.5rem;
			display: flex;
			align-items: center;
			margin-top: 0.4rem;
			justify-content: center;
		}
		
		.lf_tab_box div {
			width: 1.6rem;
			height: 0.5rem;
		}
		.lf_tab_box div:nth-last-of-type(1).lf_tab_box_select{
			border-right: 1px solid #C0153E;
		}
		
		
		.lf_tab_box_newSwiper{
			width: 7.3rem;
			height: 0.5rem;
			display: flex;
			align-items: center;
			margin-top: 0.4rem;
			justify-content: center;
		}
		.lf_tab_box_newSwiper div {
			width: 1.6rem;
			height: 0.5rem;
		}
		.lf_tab_box_newSwiper div:nth-last-of-type(1).lf_tab_box_select{
			border-right: 1px solid #C0153E;
	
		}
		.select-left{
			width: 7.1rem;
			height: 3.76rem;
			position: fixed;
			z-index: 100;
			left: 0.2rem;
			top: unset !important;
			bottom:0.14rem;
			background-color: rgba(13,0,0,0.7);
			border: rgba(255, 241, 242, 1) 0.01rem solid;
			border-radius: 0.1rem;
		}
		.select-right{
			
			width: 7.1rem;
			height: 3.76rem;
			position: fixed;
			z-index: 100;
			top: unset !important;
			left: 0.2rem;
			bottom:0.14rem;
			background-color: rgba(13,0,0,0.7);
			border: rgba(255, 241, 242, 1)  0.01rem solid;
			border-radius: 0.1rem;
			
		
		}
		.select_title{
			display: block;
			width: 7.1rem;
			height: 0.6rem;
			line-height: 0.6rem;
			color: #fff;
			overflow: hidden;
			padding-left:0.23rem;
			border-bottom: rgba(255, 241, 242, 1)  0.01rem solid;
		}
		.select-back{
			
			position: fixed;
			background-color: rgba(13,0,0,0.3);
			width: 7.5rem;
			height: 100%;
			position: fixed;
			z-index: 100;
			left: 0;
			top:0;
			
		}
		
		.select_socl{
			width: 7.1rem;
			height: 3.06rem;
			margin-top: 0.1rem;
			overflow: scroll;
			padding-bottom: 0.5rem;
		}
		
		.select_item{
			width: 7.1rem;
			height: 0.6rem;
			padding-left:0.23rem;
			margin-bottom: 0.05rem;
			line-height: 0.6rem;
			color: #fff;
			font-size: 0.24rem;
			overflow: hidden;
			cursor: pointer;
		
		}
		.select_item:hover{
			background-color: rgba(255, 241, 242, 1);;
			color: #C0153E;
		}
		.select_item_hover{
			background-color: rgba(255, 241, 242, 1);;
			color: #C0153E;
		}
		.into{
			animation-name: into;
			 animation-duration: 0.5s;
			 display: block;
		}
		.lf_entry_info_box_left_con_list_right .el-date-editor{
			width: 5rem!important;
			height: 0.6rem!important;
		}
		.lf_entry_info_box_left_con_list_right .el-select{
			width: 5rem!important;
			height: 0.6rem!important;
		}
		.lf_entry_info_box_left_con_list_right .select-trigger{
			width: 5rem!important;
			height: 0.6rem!important;
		}
		.lf_entry_info_box_left_con_list_right .el-input{
			width: 5rem!important;
			height: 0.6rem!important;
		}
		
		.lf_entry_info_box_left_con_list_right .lf_sssb{
			width: 1.6rem!important;
		}
		.lf_entry_info_box_left_con_list_right .lf_sssb1{
			width: 3.2rem!important;
		}
		.lf_entry_info_box_left{
			margin-left: 0!important;
		}
		.lf_entry_info_box_right{
			margin-right: 0!important;
		}
		@keyframes into {
		    0% {
		        height:0;
		    }
		    100% {
		        height:3.76rem;
		    }
		}
		.banner_top{
			width: 100%;
			height: 0.88rem;
		}
	}
</style>
<template>
	<navs></navs>
	<Btn cd="home" undergoing="Home"></Btn>
	<div class="lf_banner_div">
		<div class="lf_banner_div_left">
			<swiper :modules="modules" :autoplay="autoplayOptions" :loop="true"  :pagination="{ clickable: true }" @swiper="onSwiper"
				 class="lf_banner_div_left_img">
				<swiper-slide v-if="news6.length>0" v-for="b in news6">
					<router-link target="_blank" :to="{path:'/newsinfo',query:{id:b.ID,lang:systemlang}}">
						<div class="lf_banner_div_left_img_item">
							<img v-if="b.ArticleImgOrg" :src="b.ArticleImgOrg" :alt="b.ArticleTitle">
							<img v-else-if="b.ArticleImg" :src="b.ArticleImg" :alt="b.ArticleTitle">
							<img v-else src="../assets/images/u121245.png" />
							<div class="lf_news_text_tit">{{b.ArticleTitle}}</div>
						</div>
					</router-link>
				</swiper-slide>
			</swiper>
			<NewListmorenew type="search" id="search" front="6" year="" mt_id="" pl_name="" ishomepage="1" title="xinwenjingxuan" limit="8"></NewListmorenew>
		</div>
		<div v-if="true" class="lf_banner_div_right">
			<div class="right_img_Active" v-if="true">
				<!-- <img src="../assets/images/100.png"> -->
				<!-- <img v-if="match.logoappend&&match.logoappend.match_h_bannr&&match.logoappend.match_h_bannr[0]" :src="match.logoappend.match_h_bannr[0]"> -->
				<div  style="width: 100%;height: 3rem;"  v-if="match.logoappend&&match.logoappend.match_h_bannr&&match.logoappend.match_h_bannr&&match.logoappend.match_h_bannr[0]">
					<banner v-if="match.logoappend&&match.logoappend.match_h_bannr&&match.logoappend.match_h_bannr&&match.logoappend.match_h_bannr[0]" :type="1" datatype="1" :dataurl="match.logoappend.match_h_bannr"></banner>
				</div>
				<img v-else src="../assets/images/101.png">
				<div class="right_img_dark"></div>
				<div class="right_img_body">
					<router-link v-if="WebModel&&match.zt=='0'" target="_blank" :to="{path:'/Match',query:{type:'cj',bFlag:'zdcj',id:match.ID,lang:systemlang}}">
					<div class="right_img_title hoverUnderLine">{{match.ColumnName}}</div>
					</router-link>
					<router-link v-else-if="!WebModel&&match.zt=='0'" target="_blank" :to="{path:'/Match',query:{type:'cj',bFlag:'lxb',id:match.ID,lang:systemlang}}">
						<div class="right_img_title hoverUnderLine">{{match.ColumnName}}</div>
					</router-link>
					<router-link v-else-if="match.zt=='1'" target="_blank" :to="{path:'/Match',query:{type:'match',bFlag:'ssgc',id:match.ID,lang:systemlang}}">
						<div class="right_img_title hoverUnderLine">{{match.ColumnName}}</div>
					</router-link>
					
					<div class="right_img_des">{{match.mttime}}</div>
					<div class="right_img_des">{{match.ColumnPlace}}</div>
					<!-- <div class="right_img_des">{{langs.home_saishizongjiangjin}}：{{match.ColumnPrice}}</div> -->
				</div>
				<div class="lf_bbbb_box_bb">
				<div class="img_left_text hand" :class="mtchoose=='0'?'red_hover':''" v-if="curr&&future" @click="changemtchoose(0)">{{langs.home_jinxingzhong}}</div>
				<div class="img_left_text hand" :class="mtchoose=='1'?'red_hover':''" v-if="curr&&future" @click="changemtchoose(1)">{{langs.home_xiayichang}}</div>
				<div class="img_center_text red_hover" v-if="curr&&!future">{{langs.home_jinxingzhong}}</div>
				<div class="img_center_text red_hover" v-if="!curr&&future">{{langs.home_xiayichang}}</div>
				<!-- <div class="img_center_text red_hover">下一场</div> -->
				</div>
			</div>
			<div class="right_round" v-if="match&&mtchoose=='0'&&curr.zt == '0'">
				<router-link target="_blank"
						:to="{path:'/Match',query:{id:match.ID,lang:systemlang,type:'cj',bFlag:'lxb'}}">
					<div class="round_title">
						<div class="round_title_left">Round {{match.round_id}}</div>
						<div class="round_title_right">{{langs.home_more}}</div>
					</div>
				</router-link>
				<div class="round_body" v-if="match.is_gan==1">
					<div class="round_item_info">

						<div class="round_item_info_item">
							<div class="round_item_info_item_list round_item_info_item_list_tit  bottom_line">
								<div class="round_item_info_item_title_item_a" style="color: #666666;">
									{{langs.home_paiming_pos}}
								</div>
								<div class="round_item_info_item_title_item_b"  style="color: #666666;">
									<span style="margin-left: 0.8rem;">{{langs.home_qiuyuan}}</span>
								</div>
								<div class="round_item_info_item_title_item_c"  style="color: #666666;"> 
									{{langs.home_chengji}}
								</div>
								<div class="round_item_info_item_title_item_c"  style="color: #666666;">
									{{langs.home_wancheng}}
								</div>
							</div>
							<div class="round_item_info_item_list" v-for="(b,index) in match.playerscore">
								<div class="round_item_info_item_title_item_a New_center  red_text" style="color:#C0153E">
									{{b.rank}}
								</div>
								<div class="round_item_info_item_title_item_b" style="color:#333333">
									<img v-if="b.pl_country" :src="b.pl_country"><span style="margin-left: 0.1rem;">{{b.pl_name}}</span>
								</div>
								<div class="round_item_info_item_title_item_c New_center">
									<span :style="parseInt(b.topar) < 0?'color: #C0153E;':''">{{b.topar > 0?'+':''}}{{b.topar == '0'?'E':b.topar}}</span>
								</div>
								<div class="round_item_info_item_title_item_c New_center">
									<span :style="b.ok_h=='F'?'color: #C0153E;':''">{{b.ok_h==18?'F':(b.ok_h==''&&b.roundsts==''?b.start_time:b.ok_h)}}</span>{{b.hou9=='1'&&b.roundsts==''?'*':''}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="right_stats">
				<div class="stats_title">
					<div class="stats_title_left">{{langs.home_stats}} | {{langs.home_stats_en}}</div>
					<div class="stats_title_right">
						<img class="stats_title_left_icon hand" src="../assets/images/u254.svg" @click="statsindex=(statsindex-1)==0?3:(statsindex-1)"/>
						<div class="stats_title_center_one hand" :class="statsindex==1?'red_text':''" @click="changestatsindex(1)">1</div>
						<div class="stats_title_center_one hand" :class="statsindex==2?'red_text':''" @click="changestatsindex(2)">2</div>
						<div class="stats_title_center_one hand" :class="statsindex==3?'red_text':''" @click="changestatsindex(3)">3</div>
						<img class="stats_title_right_icon hand" src="../assets/images/u254.svg" @click="statsindex=(statsindex+1)>3?1:(statsindex+1)"/>
					</div>
				</div>
				<template v-for="(item,index) in list">
					<div class="stats_body" v-if="index>=statsindex*4-4&&index<statsindex*4">
						<router-link target="_blank"
							:to="{path:'/TecStatisticsinfo',query:{statstype:item.type,lang:systemlang,'year':year}}">
							<div class="stats_item_title">
								<div class="stats_item_title_left">{{item.titleleft}} | <span style="font-size: 0.22rem;">{{item.titleright}}</span></div>
								<img class="stats_title_right_icon_red" src="../assets/images/u182.png" />
							</div>
						</router-link>
						<div class="stats_item_info">
							<div class="stats_item_info_top">
								<div class="stats_item_info_top_member_img">
									<div class="imgzhong">
										<img v-if="item.first.pl_photo_img=='http://www.wifigolf.com/club/adm/images/players/none.jpg'" src="../assets/images/u17762.svg" alt="">
										
										<img v-else :src="item.first.pl_photo_img">
									</div>
									
									<img src="../assets/images/u184.png" class="img_top" />
								</div>
								<div class="stats_item_info_top_member">
									<router-link target="_blank"
									:to="{path:'/playerInfo',query:{pl_id:item.first.pl_id-200000,lang:systemlang}}">
										<div class="stats_item_info_top_member_rightinfo">
											<img :src="item.first.pl_country_img"><span class="hoverUnderLine" style="margin-left: 0.2rem;">{{item.first.pl_cn_name}}{{item.first.pl_type=="AMA"?"(A)":""}}</span>
										</div>
									</router-link>
									<div class="stats_item_info_top_member_rightinfo_bottom">
										<!-- 次数 /参赛场<span class="twoeight_text red_text" style="margin-left: 0.2rem;">11</span> -->
										<!-- <span v-if="item.field&&item.field.length>0&&('getqiudaolv'!=item.type&&'getpargreenlv'!=item.type&&'getgreensavenum'!=item.type&&'getsandsavenum'!=item.type)">{{item.field[0]}}</span>
										<span v-if="item.field&&item.field.length>0&&'getfronttennum'==item.type">/{{item.field[1]}}</span> -->

										<span class="twoeight_text red_text">
											<span v-if="'getfronttennum'==item.type||'getbirdienum'==item.type||'geteaglenum'==item.type">{{item.first.num}}</span>
											<span v-else>{{item.first.num.toFixed(1)}}</span>
											<span v-if="'getqiudaolv'==item.type||'getpargreenlv'==item.type||'getgreensavenum'==item.type||'getsandsavenum'==item.type">%</span>
											<span>{{index!=0?"":("/"+item.first.matchnum)}}</span>
										</span>
									</div>
								</div>
							</div>
							<div class="stats_item_info_item">

								<div class="stats_item_info_item_list">
									<div class="stats_item_info_item_title_item_a" style="color: #666666;">

										{{langs.home_paiming}}
									</div>
									<div class="stats_item_info_item_title_item_b">
										<span style="margin-left: 0.8rem;color: #666666;">{{langs.home_qiuyuan}}</span>
									</div>
									<div class="stats_item_info_item_title_item_c">
										<span style="color: #666666;" v-if="item.field&&item.field.length>0&&('getqiudaolv'!=item.type&&'getpargreenlv'!=item.type&&'getgreensavenum'!=item.type&&'getsandsavenum'!=item.type)">{{item.field[0]}}</span>
										<span style="color: #666666;" v-else>{{langs.home_shuju}}</span>
										<span style="color: #666666;" v-if="item.field&&item.field.length>0&&'getfronttennum'==item.type">/{{item.field[1]}}</span>
									</div>
								</div>
								<div class="stats_item_info_item_list" v-for="(item1,index1) in item.rank2to5">
									<div class="stats_item_info_item_title_item_a New_center">
										{{item1.rank_code}}{{item1.rank}}
									</div>
									<router-link target="_blank"
									:to="{path:'/playerInfo',query:{pl_id:item1.pl_id-200000,lang:systemlang}}">
										<div class="stats_item_info_item_title_item_b">
											<img :src="item1.pl_country_img"><span class="hoverUnderLine" style="margin-left: 0.2rem;">{{item1.pl_cn_name}}{{item1.pl_type=="AMA"?"(A)":""}}</span>
										</div>
									</router-link>
									<div class="stats_item_info_item_title_item_c New_center">
										<span v-if="'getfronttennum'==item.type||'getbirdienum'==item.type||'geteaglenum'==item.type">{{item1.num}}</span>
										<span v-else>{{item1.num.toFixed(1)}}</span>
										<span v-if="'getqiudaolv'==item.type||'getpargreenlv'==item.type||'getgreensavenum'==item.type||'getsandsavenum'==item.type">%</span>
										<span>{{index!=0?"":("/"+item1.matchnum)}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
	<div class="m_home">
		<div class="all_body">
			<div class="m_banner">
				<swiper :modules="modules" :autoplay="autoplayOptions" :loop="true"  :pagination="{ clickable: true }"
					@swiper="onSwiper" @slideChange="onSlideChange" class="m_swiper">
					<swiper-slide v-if="news6.length>0" v-for="b in news6">
						<router-link target="_blank" :to="{path:'/newsinfo',query:{id:b.ID,lang:systemlang}}">
							
								<div class="m_swiper_item">
									<img v-if="b.ArticleImg" :src="b.ArticleImg" :alt="b.ArticleTitle" style="	height: 4.22rem !important;">
									<img v-else src="../assets/images/u121245.png" style="height: 4.22rem !important;" />
									<div class="m_swiper_test">{{b.ArticleTitle}}</div>
								</div>
							
						</router-link>
					</swiper-slide>
				</swiper>
			</div>
			<div class="pinkBody">
				<div class="right_img_Active" v-if="match">
					<!-- <img v-if="match.logoappend&&match.logoappend.match_h_bannr&&match.logoappend.match_h_bannr[0]" :src="match.logoappend.match_h_bannr[0]"> -->
					<div style="width: 7.1rem;height: 4rem;" v-if="match.logoappend&&match.logoappend.match_h_bannr&&match.logoappend.match_h_bannr&&match.logoappend.match_h_bannr[0]">
						<banner :type="1" datatype="1" :dataurl="match.logoappend.match_h_bannr"></banner>
					</div>
					<img v-else src="../assets/images/101.png">
					<div class="right_img_dark"></div>
					<div class="right_img_body">
						<router-link v-if="WebModel&&match.zt=='0'" target="_blank" :to="{path:'/Match',query:{type:'cj',bFlag:'zdcj',id:match.ID,lang:systemlang}}">
						<div class="right_img_title">{{match.ColumnName}}</div>
						</router-link>
						<router-link v-else-if="!WebModel&&match.zt=='0'" target="_blank" :to="{path:'/Match',query:{type:'cj',bFlag:'lxb',id:match.ID,lang:systemlang}}">
							<div class="right_img_title">{{match.ColumnName}}</div>
						</router-link>
						<router-link v-else-if="match.zt=='1'" target="_blank" :to="{path:'/Match',query:{type:'match',bFlag:'ssgc',id:match.ID,lang:systemlang}}">
							<div class="right_img_title">{{match.ColumnName}}</div>
						</router-link>
						
						<div class="right_img_des">{{match.mttime}}</div>
						<div class="right_img_des">{{match.ColumnPlace}}</div>
						<!-- <div class="right_img_des">{{langs.home_saishizongjiangjin}}：{{match.ColumnPrice}}</div> -->
					</div>
					<div class="lf_bbbb_box_bb">
						<div class="img_left_text" :class="mtchoose=='0'?'red_hover':''" v-if="curr&&future" @click="changemtchoose(0)">{{langs.home_jinxingzhong}}</div>
						<div class="img_left_text" :class="mtchoose=='1'?'red_hover':''" v-if="curr&&future" @click="changemtchoose(1)">{{langs.home_xiayichang}}</div>
						<div class="img_center_text red_hover" v-if="curr&&!future">{{langs.home_jinxingzhong}}</div>
						<div class="img_center_text red_hover" v-if="!curr&&future">{{langs.home_xiayichang}}</div>
					</div>
				</div>
				<div class="right_round" v-if="match&&mtchoose=='0'&&curr.zt == '0'">
					<!-- Match?lang=cn&id=7956&type=cj&bFlag= -->
					<router-link target="_blank"
						:to="{path:'/Match',query:{id:match.ID,lang:systemlang,type:'cj',bFlag:'lxb'}}">
						<div class="round_title">
							<div class="round_title_left">Round {{match.round_id}}</div>
							<div class="round_title_right">{{langs.home_more}}</div>
						</div>
					</router-link>
					<div class="round_body" v-if="match.is_gan==1">
						<div class="round_item_info">
							<div class="round_item_info_item_list_new" v-for="(b,index) in match.playerscore">
								<div class="leftpeoplebox">
									{{b.rank}}
								</div>
								<div class="zbox">
									<div class="zwordbox">
										<div class="znamebox">{{b.pl_name}}</div>
										<div class="znumberbox" :style="b.ok_h=='F'?'color: #C0153E;':'color: #333333;'"><span style="color:#666666;">{{b.ok_h==0?"":'Thru '}}</span>{{b.ok_h==18?'F':(b.ok_h==''&&b.roundsts==''?b.start_time:b.ok_h)}}{{b.hou9=='1'&&b.roundsts==''?'*':''}}</div>
									</div>
									<div class="zimg">
										<img v-if="b.pl_country" :src="b.pl_country">
									</div>
								</div>
								<div class="rightpeoplebox" :style="parseInt(b.topar) < 0?'color: #C0153E;':''">{{b.topar > 0?'+':''}}{{b.topar == '0'?'E':b.topar}}{{b.hou9=='1'?'*':''}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NewListmore type="search" id="search" front="6" year="" mt_id="" pl_name="" ishomepage="1" title="xinwenjingxuan" limit="8"></NewListmore>
			<div class="right_stats">
				<div class="stats_title">
					<div class="stats_title_left">{{langs.home_stats}} | {{langs.home_stats_en}}</div>
					<div class="stats_title_right">
						<img class="stats_title_left_icon" src="../assets/images/u254.svg" />
						<div class="stats_title_center_one" :class="statsindex==1?'red_text':''" @click="changestatsindex(1)">1</div>
						<div class="stats_title_center_one" :class="statsindex==2?'red_text':''" @click="changestatsindex(2)">2</div>
						<div class="stats_title_center_one" :class="statsindex==3?'red_text':''" @click="changestatsindex(3)">3</div>
						<img class="stats_title_right_icon" src="../assets/images/u254.svg" />
					</div>
				</div>
				<template v-for="(item,index) in list">
					<div class="stats_body" v-if="index>=statsindex*4-4&&index<statsindex*4">
						<router-link target="_blank"
							:to="{path:'/TecStatisticsinfo',query:{statstype:item.type,lang:systemlang,'year':year}}">
							<div class="stats_item_title">
								<div class="stats_item_title_left">{{item.titleleft}} | {{item.titleright}}</div>
								<img class="stats_title_right_icon_red" src="../assets/images/u182.png" />
							</div>
						</router-link>
						<div class="stats_item_info">
							<div class="stats_item_info_top">
								<div class="stats_item_info_top_member_img">
								
									<div class="imgzhong">
										<img v-if="item.first.pl_photo_img=='http://www.wifigolf.com/club/adm/images/players/none.jpg'" src="../assets/images/u17762.svg" alt="">
										<img v-else :src="item.first.pl_photo_img">
									</div>
									
									<img src="../assets/images/u184.png" class="img_top" />
								</div>
								<div class="stats_item_info_top_member">
									<router-link target="_blank"
									:to="{path:'/playerInfo',query:{pl_id:item.first.pl_id-200000,lang:systemlang}}">
										<div class="stats_item_info_top_member_rightinfo">
											<img :src="item.first.pl_country_img"><span style="margin-left: 0.2rem;">{{item.first.pl_cn_name}}{{item.first.pl_type=="AMA"?"(A)":""}}</span>
										</div>
									</router-link>
									<div class="stats_item_info_top_member_rightinfo_bottom">
										<!-- 次数 /参赛场<span class="twoeight_text red_text" style="margin-left: 0.2rem;">11</span> -->
										<!-- <span v-if="item.field&&item.field.length>0&&('getqiudaolv'!=item.type&&'getpargreenlv'!=item.type&&'getgreensavenum'!=item.type&&'getsandsavenum'!=item.type)">{{item.field[0]}}</span>
										<span v-if="item.field&&item.field.length>0&&'getfronttennum'==item.type">/{{item.field[1]}}</span> -->
										<span class="twoeight_text red_text">
											<span v-if="'getfronttennum'==item.type||'getbirdienum'==item.type||'geteaglenum'==item.type">{{item.first.num}}</span>
											<span v-else>{{item.first.num.toFixed(1)}}</span>
											<span v-if="'getqiudaolv'==item.type||'getpargreenlv'==item.type||'getgreensavenum'==item.type||'getsandsavenum'==item.type">%</span>
											<span>{{index!=0?"":("/"+item.first.matchnum)}}</span>
										</span>
									</div>
								</div>
							</div>
							<div class="stats_item_info_item">
								<div class="stats_item_info_item_list">
									<div class="stats_item_info_item_title_item_a">
										{{langs.home_paiming}}
									</div>
									<div class="stats_item_info_item_title_item_b">
										<span style="margin-left: 0.8rem;">{{langs.home_qiuyuan}}</span>
									</div>
									<div class="stats_item_info_item_title_item_c">
										<span v-if="item.field&&item.field.length>0&&('getqiudaolv'!=item.type&&'getpargreenlv'!=item.type&&'getgreensavenum'!=item.type&&'getsandsavenum'!=item.type)">{{item.field[0]}}</span>
										<span v-else>{{langs.home_shuju}}</span>
										<span v-if="item.field&&item.field.length>0&&'getfronttennum'==item.type">/{{item.field[1]}}</span>
									</div>
								</div>
								<div class="stats_item_info_item_list" v-for="(item1,index1) in item.rank2to5">
									<div class="stats_item_info_item_title_item_a New_center">
										{{item1.rank_code}}{{item1.rank}}
									</div>
									<router-link target="_blank"
									:to="{path:'/playerInfo',query:{pl_id:item1.pl_id-200000,lang:systemlang}}">
									<div class="stats_item_info_item_title_item_b">
										<img :src="item1.pl_country_img"><span style="margin-left: 0.2rem;">{{item1.pl_cn_name}}{{item1.pl_type=="AMA"?"(A)":""}}</span>
									</div>
									</router-link>
									<div class="stats_item_info_item_title_item_c New_center">
										<span v-if="'getfronttennum'==item.type||'getbirdienum'==item.type||'geteaglenum'==item.type">{{item1.num}}</span>
										<span v-else>{{item1.num.toFixed(1)}}</span>
										<span v-if="'getqiudaolv'==item.type||'getpargreenlv'==item.type||'getgreensavenum'==item.type||'getsandsavenum'==item.type">%</span>
										<span>{{index!=0?"":("/"+item1.matchnum)}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>


		</div>

	</div>

	<Footer></Footer>
	<Footer1></Footer1>
</template>

<script>
	// @ is an alias to /src
	import navs from "../components/nav.vue";
	import Btn from "../components/Btn.vue";
	import NewListmore from "../components/NewListmore.vue";
	import NewListmorenew from "../components/NewListmorenew.vue";
	import Footer from "../components/Footer.vue";
	import Footer1 from "../components/Footer1.vue";
	import Ad from "../components/Ad.vue";
	import banner from "../components/banner.vue";
	import axios from '../axios/request';
	import {
		getSystemLang,
		getLangAll
	} from "../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'

	//自动轮播的配置
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				banner: {},
				jiangjin: {},
				bannertr: {},
				year: (new Date()).getFullYear(),
				curyear: (new Date()).getFullYear(),
				news6: [],
				list: [],
				statsindex: 1,
				match:[],
				curr:[],
				future:[],
				WebModel:true,
				mtchoose:0
			}
		},
		props: {

		},
		name: 'Home',
		components: {
			navs,
			Btn,
			NewListmore,
			Footer,
			Footer1,
			Swiper,
			SwiperSlide,
			NewListmorenew,
			Ad,
			banner
		},
		created() {

			var deviceWidth = document.documentElement.clientWidth || window.innerWidth
			if (deviceWidth >= 770) {
				this.WebModel = true;
				
			} else {
				this.WebModel = false;
			}
			
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			var year = this.year;
			axios('news/search.json', {
				limit: 6
			})
			.then((res) => {
				let data = res.data.data;
				this.news6 = data;
			});
			this.getlist(year);
			axios('matchs/match/home.json')
			.then((res) => {
				this.curr = res.data.data.curr;
				this.future = res.data.data.future;
				if(this.curr){
					this.mtchoose = 0;
					axios("matchs/match/matchtj.json",{
						otp: "playerScore",
						mt_id: this.curr.ID,
						round_id: this.curr.round_id
					})
					.then((res1) => {
						var playerscore = [];
						// for(var i=0;i<10;i++){
						// 	playerscore.push(res1.data.data.list[i]);
						// }
						for(var k in res1.data.data.list){
							if(k<10){
								playerscore.push(res1.data.data.list[k])
							}
						}
						this.curr.playerscore = playerscore;
						if(this.mtchoose == 0){
							this.match = this.curr;
							console.log(this.match);
						}
					});
				}else if(this.future){
					this.mtchoose = 1;
					this.future.playerscore = [];
					this.match = this.future;
				}
			});
		},
		setup() {
			const onSwiper = (swiper) => {
				console.log(swiper);
				swiper.slideTo(1)
			};
			const onSlideChange = (e) => {
				console.log('slide change123123123', e.activeIndex);
			};
			const autoplayOptions = {
				delay: 5000,
				disableOnInteraction: false,
				loop: true,
				pauseOnMouseEnter: true,
				reverseDirection: false
			};
			return {
				onSwiper,
				onSlideChange,
				autoplayOptions,
				modules: [Pagination, Autoplay],
			};
		},
		methods: {
			getlist: function(year) {
				var that = this;
				axios('tecstatistics/getstatslist', {
						year: year
					})
					.then((res) => {
						var list = res.data.data.data;
						for (var key in list) {
							list[key].first = {};
							list[key].rank2to5 = [];
							for (var k in list[key].list) {
								if (getSystemLang() == "cn") {
									list[key].list[k]["pl_cn_name"] = list[key].list[k]["pl_cn_name"];
									list[key].list[k]["pl_en_name"] = list[key].list[k]["pl_en_name"];
								} else {
									var temp = list[key].list[k]["pl_cn_name"];
									list[key].list[k]["pl_cn_name"] = list[key].list[k]["pl_en_name"];
									list[key].list[k]["pl_en_name"] = temp;
								}
								if (k == 0) {
									list[key].first = list[key].list[k];
								}
								if (k >= 1 && k <= 4) {
									list[key].rank2to5.push(list[key].list[k]);
								}
								if (k > 4) {
									break;
								}
							}
							if (getSystemLang() != "cn") {
								var temptitle = list[key].titleleft;
								list[key].titleleft = list[key].titleright;
								list[key].titleright = temptitle;
							}
						}
						// console.log(list);
						this.list = list;
						this.year = list[0].year;
					})
			},
			changestatsindex(statsindex){
				this.statsindex = statsindex;
			},
			changemtchoose(mtchoose){
				this.mtchoose = mtchoose;
				if(mtchoose == 0){
					axios("matchs/match/matchtj.json",{
						otp: "playerScore",
						mt_id: this.curr.ID,
						round_id: this.curr.round_id
					})
					.then((res1) => {
						var playerscore = [];
						for(var k in res1.data.data.list){
							if(k<10){
								playerscore.push(res1.data.data.list[k])
							}
						}
						this.curr.playerscore = playerscore;
						if(this.mtchoose == 0){
							this.match = this.curr;
							console.log(this.match);
						}
					});
				}else if(mtchoose == 1){
					this.future.playerscore = []
					this.match = this.future;
				}
			}
		}
	}
</script>


<style scoped="scoped">
	.lf_banner_div {
		width: 100%;
		height: auto;
		position: relative;
		overflow: hidden;
		margin-top: 0.3rem;
	}

	.lf_banner_div_left {
		float: left;
		width: 13.2rem;
		overflow: hidden;
		margin-left: 0.2rem;
	}

	.lf_banner_div_left_img {
		width: 13.2rem;
		height: 7.42rem;
	}

	.lf_banner_div_left_img_item {
		width: 13.2rem;
		height: 7.42rem;
		position: relative;

	}

	.lf_news_text_tit {
		width: 100%;
		font-size: 0.3rem;
		color: #fff;
		font-weight: bold;
		height: 0.84rem;
		line-height: 0.84rem;
		left: 0;
		background-color: rgba(192, 21, 62, 0.8);
		position: absolute;
		bottom: 0;
		padding-left: 0.4rem;

	}

	.lf_banner_div_right {
		float: right;
		width: 5.4rem;
		margin-right: 0.2rem;
		overflow: hidden;
		
	}

	.right_img_Active {
		width: 100%;
		height: 3.4rem;
		position: relative;
	}

	.right_img_body {
		position: absolute;
		width: 100%;
		height: 3rem;
		background-color: rgba(0, 0, 0, 0.5);
		top: 0;
		left: 0;
		z-index: 1;
	}

	.right_img_title {
		font-size: 0.28rem;
		height: 0.37rem;
		line-height: 0.37rem;
		color: #fff;
		font-weight: bold;
		text-align: center;
		width: 100%;
		margin-top: 0.75rem;
		margin-bottom: 0.5rem;
	}

	.right_img_des {
		font-size: 0.24rem;
		
		line-height: 0.31rem;
		color: #fff;

		text-align: center;
		width: 100%;

	}

	.img_left_text {
		width: 2.7rem;
		float: left;
		text-align: center;
		font-size: 0.24rem;
		height: 0.35rem;
		line-height: 0.35rem;
		border: #999999 1px solid;
		color: #999999;
	}

	.img_center_text {
		width: 100%;

		text-align: center;
		font-size: 0.24rem;
		height: 0.35rem;
		line-height: 0.35rem;
		border: #999999 1px solid;
		color: #999999;
	}
	.lf_bbbb_box_bb{
		display: flex;
		align-items: center;
	}
	.lf_bbbb_box_bb div:nth-of-type(1){
		border-bottom-left-radius: 0.06rem;
	}
	.lf_bbbb_box_bb div:nth-last-of-type(1){
		border-bottom-right-radius: 0.06rem;
	}
	.red_hover {
		color: #C0153E;
		border: #C0153E 1px solid;

	}

	.right_img_Active img {
		width: 100%;

		height: 3rem;
	}

	.right_round {
		width: 100%;
		margin-top: 0.1rem;
		background-color: #f9f9f9;
	}

	.round_title {
		width: 100%;
		background-color: #C0153E;

		height: 0.5rem;
		line-height: 0.5rem;
	}

	.round_title_left {
		font-size: 0.24rem;
		font-weight: bold;
		float: left;
		color: #fff;
		margin-left: 0.3rem;
	}

	.round_title_right {
		font-size: 0.22rem;

		float: right;
		color: #fff;
		margin-right: 0.3rem;
	}

	.round_body {
		width: 100%;
		margin-bottom: 0.3rem;

	}

	.round_item_title {
		width: 100%;
		height: 0.5rem;
		line-height: 0.5rem;
		color: #C0153E;
		background-color: #FAFAFA;

	}

	.round_item_title_left {
		font-size: 0.26rem;
		margin-left: 0.3rem;
	}




	.round_item_info {
		width: 100%;
	}

	.bottom_line {
		border-bottom: rgba(102, 102, 102) 1px solid;
		margin-bottom: 0.2rem;
	}

	.round_item_info_top {
		width: 4.9rem;
		height: 1.51rem;
		margin-left: 0.25rem;
		border-bottom: #FAFAFA 1px solid;
	}

	.round_item_info_top_member_img {
		float: left;
		width: 1.44rem;
		height: 1.44rem;
		position: relative;
	}

	.round_item_info_top_member_img img {
		width: 1.2rem;
		height: 1.2rem;
		float: right;
		margin-top: 0.12rem;
		border-radius: 100%;
	}

	.round_item_info_top_member {
		float: right;
	}

	.round_item_info_item {
		width: 5.15rem;

		margin-left: 0.25rem;
	}
	.round_item_info_item_list_tit{
		height:0.7rem!important;
		display: flex;
		align-items: center;
	}
	.round_item_info_item_list {
		width: 4.9rem;
		font-size: 0.24rem;
		overflow: hidden;
		height: 0.56rem;
		line-height: 0.56rem;
	}

	.round_item_info_item_title_item_a {
		float: left;
		width: 0.5rem;
		color: #333333;
	}

	.round_item_info_item_title_item_b {
		float: left;
		width: 2.8rem;
	}

	.round_item_info_item_title_item_b img {
		float: left;
		width: 0.29rem;
		height: 0.2rem;
		margin-top: 0.18rem;
		margin-left: 0.1rem;
	}

	.round_item_info_item_title_item_c {
		float: left;
		width: 0.8rem;
		text-align: center;
	}


	.right_stats {
		width: 100%;
		margin-top: 0.3rem;

		border: #C0153E 1px solid;
		border-radius: 0.1rem 0.1rem 0 0;
		overflow: hidden;
	}

	.stats_title {
		width: 100%;
		background-color: #C0153E;

		height: 0.5rem;
		line-height: 0.5rem;
	}

	.stats_title_left {
		font-size: 0.26rem;
		font-weight: bold;
		float: left;
		color: #fff;
		margin-left: 0.3rem;
	}

	.stats_title_right {
		height: 0.35rem;
		float: right;
		margin-top: 0.07rem;
		margin-right: 0.1rem;
	}

	.stats_title_left_icon {
		width: 0.14rem;
		height: 0.25rem;
		float: left;
		margin-top: 0.05rem;
	}

	.stats_title_right_icon {
		width: 0.14rem;
		height: 0.25rem;
		float: left;
		margin-top: 0.05rem;
		margin-left: 0.1rem;
		transform: rotate(180deg);
	}

	.stats_title_right_icon_red {
		width: 0.3rem;
		height: 0.3rem;
		float: right;
		margin-top: 0.1rem;
		margin-right: 0.1rem;

	}

	.stats_title_center_one {
		width: 0.35rem;
		height: 0.35rem;
		float: left;
		border-radius: 0.35rem;
		background-color: #fff;
		text-align: center;
		line-height: 0.35rem;
		font-size: 0.24rem;
		color: #666666;
		margin-left: 0.1rem;

	}


	.stats_body {
		width: 100%;
		margin-bottom: 0.3rem;

	}

	.stats_item_title {
		width: 100%;
		height: 0.5rem;
		line-height: 0.5rem;
		color: #C0153E;
		background-color: #FAFAFA;

	}

	.stats_item_title_left {
		font-size: 0.26rem;
		margin-left: 0.3rem;
		float: left;
	}




	.stats_item_info {
		width: 100%;
	}

	.stats_item_info_top {
		
		width: 6.6rem;
		height: 1.51rem;
		margin-left: 0.25rem;
		border-bottom: #FAFAFA 1px solid;
		overflow: hidden;

	}

	.stats_item_info_top_member_img {
		float: left;
		
		width: 1.44rem;
		height: 1.44rem;
		position: relative;
	}

	.imgzhong  {
		width: 1.2rem;
		height: 1.2rem;
		overflow: hidden;
		float: right;
		margin-top: 0.24rem;
		border-radius: 50%;
		display: flex;
		justify-content: center;
	}
	.imgzhong img {
		width: 1.2rem;
		object-fit: cover;
		justify-content: center;
	}

	.img_top {
		width: 0.59rem !important;
		height: 0.59rem !important;
		position: absolute;
		top: -0.16rem;
		left: -0.02rem;
		transform: rotate(340deg);
		float: right;
		margin-top: 0.24rem;
	
	}

	.stats_item_info_top_member {
		float: left;
		margin-left: 0.4rem;
	}

	.stats_item_info_top_member_rightinfo {
		width: 3.1rem;
		font-size: 0.26rem;
		height: 0.56rem;
		line-height: normal;
		margin-top: 0.2rem;
		color: #000;
		display: flex;
		align-items: center;
	}


	.stats_item_info_top_member_rightinfo img {
		float: left;
		width: 0.29rem;
		height: 0.2rem;

	}

	.stats_item_info_top_member_rightinfo_bottom {
		margin-top: 0.24rem;
		color: #666666;
		font-size: 0.24rem;
		display: flex;
		align-items: center;
		position: relative;
	}

	.stats_item_info_item {
		width: 4.9rem;

		margin-left: 0.25rem;
	}

	.stats_item_info_item_list {
		width: 100%;
		font-size: 0.24rem;
		overflow: hidden;
		height: 0.56rem;
		line-height: 0.56rem;
	}
	.stats_item_info_item_list_tit{
		color:#666;
	}
	.stats_item_info_item_title_item_a {
		float: left;
		width: 0.5rem;
	}

	.stats_item_info_item_title_item_b {
		float: left;
		width: 2.8rem;
	}

	.stats_item_info_item_title_item_b img {
		float: left;
		width: 0.29rem;
		height: 0.2rem;
		margin-top: 0.18rem;
		margin-left: 0.3rem;
	}

	.stats_item_info_item_title_item_c {
		float: left;
		width: 1.6rem;
	
		text-align: center;
	}

	.New_center {
		text-align: center;
	}

	.lf_Pos_div {
		position: absolute;
		bottom: 0;
		right: 3.6rem;
		z-index: 9;
		width: 4.75rem;
		height: 7.3rem;
		background: inherit;
		background-color: rgba(91, 91, 91, 0.3);
		border: none;
		border-radius: 0.3rem;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.lf_Pos_tit_text {
		font-family: '微软雅黑 Bold', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 0.24rem;
		letter-spacing: 0.05rem;
		color: #C0153E;
		margin: 0.5rem auto 0.4rem;
		text-align: center;
	}

	.lf_Pos_tit_div {
		width: 4.02rem;
		padding: 0 0.1rem;
		height: 0.5rem;
		font-size: 0.18rem;
		display: flex;
		align-items: flex-start;
		margin: 0 auto 0.16rem;
		border-bottom: 1px solid #D2D3D5;
		color: #fff;
	}

	.lf_1 {
		width: 0.6rem;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
	}

	.lf_2 {
		width: 1.1rem;
		flex-grow: 1;
		display: flex;
		align-items: center;
	}

	.lf_3 {
		width: 0.7rem;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
	}

	.lf_4 {
		width: 0.75rem;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
	}

	.lf_5 {
		width: 0.66rem;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
	}

	.lf_Pos_cons_div {
		width: 4.02rem;
		padding: 0 0.1rem;
		height: 0.52rem;
		font-size: 0.14rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
		color: #fff;
	}

	.lf_1 img {
		width: 0.21rem;
		height: 0.23rem;
	}

	.lf_2 img {
		width: 0.24rem;
		height: 0.24rem;
		border-radius: 50%;
		margin-right: 0.1rem;
	}

	.lf_3 img {
		width: 0.2rem;
		height: 0.14rem;
	}

	.lf_bannertr {
		display: none;
	}

	.red_text {
		color: #C0153E;
	}

	.twoeight_text {
		font-size: 0.28rem;
		font-weight: bold;
		position: absolute;
		right: 0.06rem ;
		width: 1.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.m_home {
		display: none;
	}

	@media screen and (max-width: 768px) {
		.twoeight_text {
			font-size: 0.28rem;
			font-weight: bold;
			position: absolute;
			right: -1.66rem ;
			width: 1.6rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.lf_banner_div {
			display: none;
		}

		.m_home {
			display: block;
		}

		.m_banner {
			width: 100%;
			height: 4.22rem;
		}

		.m_swiper {
			width: 100%;
			height: 4.22rem;
		}

		.m_swiper_item {
			width: 100%;
			height: 4.22rem;
			position: relative;
		}

		.m_swiper_item img {
			width: 100%;
			height: 4.22rem;
		}

		.m_swiper_test {
			width: 100%;
			font-size: 0.26rem;
			color: #fff;
			font-weight: bold;
			height: 0.45rem;
			line-height: 0.45rem;
			left: 0;
			background-color: #C0153E;
			position: absolute;
			bottom: 0;
			padding-left: 0.2rem;

		}

		.pinkBody {
			width: 7.5rem;
			padding-top: 0.2rem;
			background-color: #F9E8Ec;
			margin-top: 0.2rem;
			padding-bottom: 0.2rem;
		}

		.right_img_Active {
			width: 7.1rem;
			height: 4.4rem;
			position: relative;
			margin-left: 0.2rem;
		}

		.right_img_body {
			position: absolute;
			width: 7.1rem;
			height: 4rem;
			background-color: rgba(0, 0, 0, 0.5);
			top: 0;
			left: 0;
			z-index: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.right_img_title {
			font-size: 0.28rem;
			height: 0.37rem;
			line-height: 0.37rem;
			color: #fff;
			font-weight: bold;
			text-align: center;
			width: 100%;
			margin-top: 0;
			margin-bottom: 0.7rem;
		}

		.right_img_des {
			font-size: 0.24rem;
			height: 0.31rem;
			line-height: 0.31rem;
			color: #fff;

			text-align: center;
			width: 100%;

		}

		.img_left_text {
			width: 3.55rem;
			float: left;
			text-align: center;
			font-size: 0.24rem;
			height: 0.35rem;
			line-height: 0.35rem;
			border: #999999 1px solid;
			color: #999999;
		}

		.img_center_text {
			width: 7.1rem;
			background-color: #fff;
			text-align: center;
			font-size: 0.24rem;
			height: 0.4rem;
			line-height: 0.4rem;
			border: #999999 0.01rem solid;
			color: #999999;
		}

		.red_hover {
			color: #C0153E;
			border: #C0153E 1px solid;

		}

		.right_img_Active img {
			width: 7.1rem;

			height: 4rem;
		}

		.right_round {
			width: 7.1rem;
			margin-top: 0.1rem;
			margin-left: 0.2rem;
			background-color: unset;

		}

		.round_title {
			width: 7.1rem;
			background-color: #C0153E;

			height: 0.4rem;
			line-height: 0.4rem;
			border-radius: 0.1rem;
		}

		.round_title_left {
			font-size: 0.24rem;
			font-weight: bold;
			float: left;
			color: #fff;
			margin-left: 0.1rem;
		}

		.round_title_right {
			font-size: 0.22rem;

			float: right;
			color: #fff;
			margin-right: 0.1rem;
		}

		.round_body {
			width: 7.1rem;
			margin-top: 0.1rem;
			height: 0.8rem;
			margin-bottom: unset;
		}

		.round_item_info {
			width: 7.1rem;
			height: 0.8rem;
			overflow: hidden;
			overflow-x: auto;
			margin-top: 0.1rem;
			white-space: nowrap;

		}

		.round_item_info_item_list_new {
			display: inline-block;
			background-color: #fff;
			border-radius: 0.1rem;
			margin-right: 0.1rem;
			height: 0.8rem;
			padding: 0 0.1rem;

		}


		.right_stats {
			width: 7.1rem;
			margin-left: 0.2rem;
			margin-top: 0.1rem;
		}

		.stats_item_info_item {
			width: 6.6rem;
		}

		.stats_item_info_item_title_item_b {
			width: 4.5rem;
		}



		.leftpeoplebox {
			width: 0.6rem;
			height: 0.8rem;
			line-height: 0.8rem;
			font-size: 0.24rem;
			text-align: center;
			float: left;
		}

		.zbox {
			overflow: hidden;
			height: 0.8rem;
			float: left;
		}

		.zwordbox {
			float: left;
			height: 0.8rem;
		}

		.znamebox {
			overflow: hidden;
			height: 0.4rem;
			line-height: 0.46rem;
			font-size: 0.26rem;
			color: #333333;
		}

		.znumberbox {
			overflow: hidden;
			height: 0.4rem;
			line-height: 0.4rem;
			font-size: 0.24rem;
			color: #666666;
		}

		.zimg {
			width: 0.31rem;
			height: 0.21rem;
			float: left;
			margin-left: 0.1rem;
			margin-top: 0.2rem;
			margin-right: 0.3rem;
		}

		.zimg img {
			width: 0.31rem;
			height: 0.21rem;
			margin-top: -0.3rem;
		}

		.rightpeoplebox {
			float: left;
			width: 0.6rem;
			height: 0.8rem;
			line-height: 0.8rem;
			font-size: 0.26rem;
			color: #C0153E;
			font-weight: bold;
		}

	}
</style>
<style>
	.swiper .swiper-slide {
		height: auto !important;
		width: 100% !important;

	}

	.swiper .swiper-slide img {
		height: auto !important;
		width: 100% !important;
	}

	.swiper-pagination {
		text-align: left;
		bottom: 1.15rem !important;
		padding-left: 0.3rem;
	}

	.swiper-pagination-bullet {
		background-color: #fff;
		opacity: 1;
		width: 0.15rem;
		height: 0.15rem;
		margin-right: 0.1rem !important;
	}
	
	@media screen and (max-width: 768px) {
			.m_home .m_banner .swiper-pagination {
					
				bottom: 0.5rem !important;
				padding-left: 0.1rem;
			}
			
	}
</style>
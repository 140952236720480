<template>
     <div class="lf_footer1" v-if="hezuoshang&&hezuoshang.length  > 0">
        <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="24" :md="24">
				<div class="lf_match_info_box">
				<el-col :xs="16" :sm="12" :md="12">
					<div class="d_title">{{langs.text99_Sponsors_cn}} | <span>{{langs.text99_Sponsors_en}}</span></div>
				</el-col>
				<el-col :xs="8" :sm="12" :md="12" style="display: flex;align-items: center;">
					<div class="d_title_r">
                        <!-- ,query:{lang:systemlang} -->
						<router-link target="_blank" :to="{path:'/Enterprise',query:{id:'hezuo','mt_id':mt_id,lang:systemlang}}">
							<div class="lf_go_more">{{langs.Enterprise}} &nbsp;<img src="../assets/images/u107p002.png" alt=""></div>
						</router-link>
					</div>
				</el-col>
				</div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24">
                <div class="lf_footer1_img_div">
                    <a v-for="b in hezuoshang" :href="b.LinkWebUrl" target="_blank">
                        <img style="width: unset;height: unset;" class="lf_link_con_box_img lf_link_con_box_img_mobile" :src="b.LinkLogoUrl" alt="">
                    </a>
                    
                </div>
            </el-col>
            
        </el-row>
    </div>
    <div class="lf_footer1" v-if="hezuo&&hezuo.length  > 0">
        <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="24" :md="24">
				<div class="lf_match_info_box">
				<el-col :xs="16" :sm="12" :md="12">
					<div class="d_title">{{langs.text99_TourPartners_cn}} | <span>{{langs.text99_TourPartners_en}}</span></div>
				</el-col>
				<el-col :xs="8" :sm="12" :md="12" style="display: flex;align-items: center;">
					<div class="d_title_r">
                        <!-- query:{lang:systemlang} -->
						<router-link target="_blank" :to="{path:'/Enterprise',query:{id:'hezuo',lang:systemlang}}">
							<div class="lf_go_more">{{langs.Enterprise}} &nbsp;<img src="../assets/images/u107p002.png" alt=""></div>
						</router-link>
					</div>
				</el-col>
				</div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24">
                <div class="lf_footer1_img_div">
                    <a v-for="b in hezuo" :href="b.LinkWebUrl" target="_blank">
                        <img class="lf_link_con_box_img" :src="b.LinkLogoUrl" alt="">
                    </a>
                    
                </div>
            </el-col>
            
        </el-row>
    </div>
    <div class="lf_footer1" v-if="gongying&&gongying.length  > 0">
        <el-row  type="flex" justify="center">
            <el-col :xs="24" :sm="24" :md="24">
				<div class="lf_match_info_box">
				<el-col :xs="16" :sm="12" :md="12">
					<div class="d_title">{{langs.text99_TourSuppliers_cn}} | <span>{{langs.text99_TourSuppliers_en}}</span></div>
				</el-col>
				<el-col :xs="8" :sm="12" :md="12" style="display: flex;align-items: center;">
					<div class="d_title_r">
						<router-link target="_blank" :to="{path:'/Enterprise',query:{id:'gongying',lang:systemlang}}">
							<div class="lf_go_more">{{langs.Enterprise}} &nbsp;<img src="../assets/images/u107p002.png" alt=""></div>
						</router-link>
					</div>
				</el-col>
				</div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24">
                <div class="lf_footer1_img_div">
                    <a v-for="b in gongying" :href="b.LinkWebUrl" target="_blank">
                        <img class="lf_link_con_box_img" :src="b.LinkLogoUrl" alt="">
						
                    </a>
                   
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from '../axios/request';
import { getSystemLang, getLangAll } from "../lang";
    export default {
        name: 'Footer',
        props: {
            msg: String,
            mt_id:String,
            year:String
        },
        data() {
            return {
                langs: '',
                systemlang: 'cn',
                gongying:{},
                hezuo:{},
                gongyingshang:{},
                hezuoshang:{}

            };
        },
        watch:{
            year(newName,oldName){
                if(newName != oldName)
				 {
					 //获取banner
                     axios('news/link/gongying.json',{year:newName,limit:'all'})
                    .then((res)=>{
                        let data = res.data.data;
                        this.gongying = data;
                    });

                    axios('news/link/hezuo.json',{year:newName,limit:'all'})
                    .then((res)=>{
                        let data = res.data.data;
                        this.hezuo = data;
                    });
				 }
            },
			mt_id(newName,oldName){
				 //获取banner
				 if(newName != oldName)
				 {
					if(this.mt_id)
                    {
                        axios('news/link/gongying.json',{mt_id:newName,limit:'all'})
                        .then((res)=>{
                            let data = res.data.data;
                            this.gongyingshang = data;
                        });

                        axios('news/link/hezuo.json',{mt_id:this.mt_id,limit:'all'})
                        .then((res)=>{
                            let data = res.data.data;
                            this.hezuoshang = data;
                        });


                    }
				 }
				 
				
			}
		},
        created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
            //获取banner
            axios('news/link/gongying.json',{limit:'all'})
            .then((res)=>{
                let data = res.data.data;
                this.gongying = data;
            });

            axios('news/link/hezuo.json',{limit:'all'})
            .then((res)=>{
                let data = res.data.data;
                this.hezuo = data;
            });
            console.log('mt_id',this.mt_id)
            if(this.mt_id)
            {
                axios('news/link/gongying.json',{mt_id:this.mt_id,limit:'all'})
                .then((res)=>{
                    let data = res.data.data;
                    this.gongyingshang = data;
                });

                axios('news/link/hezuo.json',{mt_id:this.mt_id,limit:'all'})
                .then((res)=>{
                    let data = res.data.data;
                    this.hezuoshang = data;
                });


            }
            

        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.lf_rank_list_tit_box{
		display: flex;

	}
	.d_title_r{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		margin-right: 0.2rem;
	}
	.d_title_r img{
		width: 120px;
		height: 20px;
	}
	.lf_go_more{
		height: 0.4rem;
		font-size: 0.24rem;
		font-family: '微软雅黑';
		font-weight: 400;
		color: #C0153E;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(192, 21, 62, 1);
		border-radius: 2px;
		margin-left: 0.1rem;
		padding-left: 0.1rem;
	}
	.lf_go_more img{
		transform: rotate(180deg);
		width: 0.33rem;
		height: 0.3rem;
	}
	.lf_News_list_box {
	    display: flex;
	    width: 100%;
	    align-items: center;
	    padding: 0.3rem 0;
	}
	
    .lf_footer1 {
        margin: 0.4rem auto;
		width: 18.5rem;
		
    }


    .lf_footer1_img_div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom:0.4px;
    }

    .lf_footer1_img_div a {
        width: auto;
    }

    .lf_link_con_box_img {
        width: 1.27rem;
        height: 0.6rem;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
        margin-right: 0.4rem;
    }

    .lf_footer1_tit_box {
        height: 1.4rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .lf_footer1_tit_box .lf_foot_tit_text1 {
        font-family: '微软雅黑';
        font-weight: 400;
        font-style: normal;
        font-size: 0.26rem;
        color: #C0153E;
    }

    .lf_footer1_tit_box .lf_foot_tit_text2 {
        font-family: '微软雅黑';
        font-weight: 400;
        font-style: normal;
        font-size: 0.15rem;
        color: #C0153E;
    }

    .lf_footer1_tit_box .lf_foot_tit_line {
        width: 2.1rem;
        height: 3px;
        background-color: #C0153E;
        margin: 4px 0;
    }

    .lf_footer1_btn_go {
        width: 2.2rem;
        height: 0.62rem;
        background: inherit;
        background-color: rgba(255, 255, 255, 1);
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(192, 21, 62, 1);
        border-radius: 2px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        font-family: '微软雅黑';
        font-weight: 400;
        font-style: normal;
        font-size: 0.2rem;
        color: #C0153E;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 0.5rem;
    }

    .lf_footer1_btn_go img {
        transform: rotate(180deg);
        width: 0.24rem;
        height: 0.22rem;
        margin-left: 0.24rem;
    }

    @media screen and (max-width: 768px) {
        .lf_footer1 {
            width: 100%;
            margin: 0.2rem auto;
        }
		.lf_match_info_box{
			margin-bottom: 0.2rem;
			width:7.02rem;
			margin:0 auto;
		}
		.d_title_r{
			margin-right: 0;
		}
        .lf_link_con_box_img {
            width: 1.4rem;
            height: unset;
            margin: 0.3rem 0.1rem;
        }
		.lf_link_con_box_img_mobile{
			width: 98%!important;
		}
		.lf_rank_list_tit_box{
			margin: 0.2rem 0;
		}
		.lf_go_more{
			width: 1.6rem;
			height: 0.4rem;
			font-size: 0.24rem;
			margin-right: 0.1rem;
			padding-left: 0.05rem;
		}
		.lf_go_more img{
			width: 0.33rem;
			height: 0.3rem;
		}
		.d_title,.d_title span{
			font-size: 0.26rem;
		}
    }
</style>
